import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from 'react-loader-spinner';
import { changePassword, resetChangePassword } from "../../Redux/Actions/Auth/ChangePasswordAction";


const ChangePasswordModal = ({ isVisible, onClose }) => {
    const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({
        "old_password": "",
        "new_password": "",
        "confirm_password": ""
    });
    const [errors, setErrors] = useState({});
    const changePasswordStatus = useSelector((state) => state.change_password_reducer.changePassword);
    // function to validate form values
    const validateForm = () => {
        const newErrors = {};
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!formValues.old_password.trim()) newErrors.old_password = "Password is required.";
        if (!formValues.new_password.trim()) newErrors.new_password = "Password is required.";
        else if (!passwordRegex.test(formValues.new_password)) newErrors.new_password = "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character";
        if (!formValues.confirm_password.trim()) newErrors.confirm_password = "Confirm password is required";
        else if (formValues.new_password !== formValues.confirm_password) newErrors.confirm_password = "Password do not match!"
        return newErrors;
    };
    const toggleCurrentPasswordVisibility = () => {
        setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
    };
    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordVisible(!isNewPasswordVisible);
    };
    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
        setErrors({});
    };
    // function dispatch change password action after validation
    const handleUpdatePassword = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            dispatch(changePassword(formValues));
        } else {
            setErrors(formErrors);
        }
    };
    // reset form values and close change password modal after password changed successfully
    useEffect(() => {
        if (changePasswordStatus?.status === true) {
            setFormValues({
                "old_password": "",
                "new_password": "",
                "confirm_password": ""
            });
            dispatch(resetChangePassword());
            onClose();
        }

    }, [changePasswordStatus])
    return (
        <div
            className={`fixed inset-0 z-50 px-[16px] flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300
                 ${isVisible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
                 `}
        >
            <div className="relative flex flex-col p-[16px] gap-[24px] rounded-[16px] bg-neutral-1 max-w-[350px] shadow-shadow-6 shadow-shadow-7 lg:gap-[40px] lg:p-[32px] lg:max-w-[568px]">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 flex items-center justify-center w-[24px] h-[24px] hover:bg-neutral-4 rounded-[48px]">
                    <span className="w-[12px] h-[12px]">
                        <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2 12.5001L22.35 3.3501C22.6875 3.0126 22.6875 2.4876 22.35 2.1501C22.0125 1.8126 21.4875 1.8126 21.15 2.1501L12 11.3001L2.84998 2.1501C2.51248 1.8126 1.98748 1.8126 1.64998 2.1501C1.31248 2.4876 1.31248 3.0126 1.64998 3.3501L10.8 12.5001L1.64998 21.6501C1.31248 21.9876 1.31248 22.5126 1.64998 22.8501C1.79998 23.0001 2.02498 23.1126 2.24998 23.1126C2.47498 23.1126 2.69998 23.0376 2.84998 22.8501L12 13.7001L21.15 22.8501C21.3 23.0001 21.525 23.1126 21.75 23.1126C21.975 23.1126 22.2 23.0376 22.35 22.8501C22.6875 22.5126 22.6875 21.9876 22.35 21.6501L13.2 12.5001Z" fill="#8C8C8C" />
                        </svg>
                    </span>
                </button>
                <div className="flex flex-col gap-[8px] lg:gap-[16px]">
                    <p className="text-font_color-primary Heading-20 text-center lg:Heading-40">Update Your Password</p>
                    <p className="text-font_color-body body-3 text-center lg:body-2">Keep your account secure by regularly updating your password.</p>
                </div>
                <div className="flex flex-col gap-[24px] text-font_color-primary">
                    <div className="w-full flex flex-col gap-[5px] lg:gap-[10px]">
                        <label className="subtitle2 lg:subtitle1">
                            Current Password
                        </label>
                        <div className="relative w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center">
                            <input
                                type={isCurrentPasswordVisible ? 'text' : 'password'}
                                name="old_password"
                                value={formValues.old_password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                className="w-full h-full bg-transparent border-none outline-none p-[8px_16px] rounded-[6px]"
                            />
                            <div
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                onClick={toggleCurrentPasswordVisibility}
                            >
                                {isCurrentPasswordVisible ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        {errors.old_password && <p className="text-red-500 body-3 lg:body-2">{errors.old_password}</p>}
                    </div>
                    <div className="w-full flex flex-col gap-[5px] lg:gap-[10px]">
                        <label className="subtitle2 lg:subtitle1">
                            New Password
                        </label>
                        <div className="relative w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center">
                            <input
                                type={isNewPasswordVisible ? 'text' : 'password'}
                                name="new_password"
                                value={formValues.new_password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                className="w-full h-full bg-transparent border-none outline-none p-[8px_16px] rounded-[6px]"
                            />
                            <div
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                onClick={toggleNewPasswordVisibility}
                            >
                                {isNewPasswordVisible ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        {errors.new_password && <p className="text-red-500 body-3 lg:body-2">{errors.new_password}</p>}
                    </div>
                    <div className="w-full flex flex-col gap-[5px] lg:gap-[10px]">
                        <label className="subtitle2 lg:subtitle1">
                            Confirm Password
                        </label>
                        <div className="relative w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center">
                            <input
                                type={isConfirmPasswordVisible ? 'text' : 'password'}
                                name="confirm_password"
                                value={formValues.confirm_password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                className="w-full h-full bg-transparent border-none outline-none p-[8px_16px] rounded-[6px]"
                            />
                            <div
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                onClick={toggleConfirmPasswordVisibility} // Toggle visibility on click
                            >
                                {isConfirmPasswordVisible ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        {errors.confirm_password && <p className="text-red-500 body-3 lg:body-2">{errors.confirm_password}</p>}
                    </div>
                    <button
                        className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                        onClick={handleUpdatePassword}
                    >
                        {changePasswordStatus === "isLoading" ? (
                            <TailSpin color="#FFFFFF" height={20} width={20} />
                        ) : (
                            "Update Password"
                        )}
                    </button>

                </div>
            </div>

        </div>
    )
}

export default ChangePasswordModal;