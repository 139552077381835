import { BASE_URL } from '../Constants';
import axiosInstance from './AxiosInstance';

// Base URL for your API
const API_URL = BASE_URL;
const GET_LESSON = "get-lesson/";
const EVALUATE_ASSESSMENT = "evaluate-assessment/";


class LessonServices {
    async getLessonsContent(body) {
        return axiosInstance.post(API_URL + GET_LESSON, body);
    }
    async evaluateAssessment(body) {
        return axiosInstance.post(API_URL + EVALUATE_ASSESSMENT, body);
    }

}

export default new LessonServices();