import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

const AssesmentResultModal = ({ assessmentResult, nextLesson }) => {
    const [animationData, setAnimationData] = useState(null);
    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/quiz_completed.json')
            .then((response) => response.json())
            .then((data) => setAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);
    return (
        <div className="flex flex-col w-[350px] shadow-questioncard rounded-[10px] items-center gap-[24px] py-[32px] px-[16px] bg-neutral-1 relative">
            <span className='w-[12px] h-[12px] absolute top-5 right-5'>
                <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2 12.5001L22.35 3.3501C22.6875 3.0126 22.6875 2.4876 22.35 2.1501C22.0125 1.8126 21.4875 1.8126 21.15 2.1501L12 11.3001L2.84998 2.1501C2.51248 1.8126 1.98748 1.8126 1.64998 2.1501C1.31248 2.4876 1.31248 3.0126 1.64998 3.3501L10.8 12.5001L1.64998 21.6501C1.31248 21.9876 1.31248 22.5126 1.64998 22.8501C1.79998 23.0001 2.02498 23.1126 2.24998 23.1126C2.47498 23.1126 2.69998 23.0376 2.84998 22.8501L12 13.7001L21.15 22.8501C21.3 23.0001 21.525 23.1126 21.75 23.1126C21.975 23.1126 22.2 23.0376 22.35 22.8501C22.6875 22.5126 22.6875 21.9876 22.35 21.6501L13.2 12.5001Z" fill="#8C8C8C" />
                </svg>
            </span>
            <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col items-center gap-[8px]">
                    <Lottie
                        animationData={animationData}
                        className="w-[146px] h-[96px]"
                    />
                    <p className="Heading-18 text-center text-font_color-primary">Congratulations on completing the quiz!</p>
                </div>
                <div className="flex items-center justify-center gap-[16px]">
                    <div className="flex flex-col p-[8px]">
                        <p className="text-center font-open_sans text-[34px] font-bold text-primary-1">{assessmentResult.correct}</p>
                        <p className="text-center text-font_color-primary button1">Correct</p>
                    </div>
                    <hr className="w-[24px] border-1 border-[#BFBFBF] transform rotate-90"></hr>
                    <div className="flex flex-col p-[8px]">
                        <p className="text-center font-open_sans text-[34px] font-bold text-redlight">{assessmentResult.wrong}</p>
                        <p className="text-center text-font_color-primary button1">Wrong</p>
                    </div>
                </div>
            </div>
            <button
                onClick={nextLesson}
                className="button2 px-[20px] py-[10px] gap-[10px] bg-primary-1 text-neutral-1 rounded-[6px] hover:bg-primary-4 w-fit lg:button2 lg:px-[28px] lg:py-[13px]"
            >
                Start Next Lesson
            </button>

        </div>
    )
}

export default AssesmentResultModal;