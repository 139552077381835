import React from "react";

const StatusLabel = ({ label, labelAction }) => {

    return (
        <div className="absolute top-6 right-6 flex justify-center items-center p-4 bg-[#EBFBF4] rounded-lg z-40">
            <p className="text-center text-[14px] font-open_sans text-[#333]">{label}</p>

            <div>
                <button
                    className="w-[100px] h-[30px] bg-white rounded-lg flex justify-center text-[#34D795] border-2 border-[#34D795] text-center items-center ml-4"
                    onClick={labelAction}
                >
                    Skip
                </button>
            </div>
        </div>
    );
}

export default StatusLabel;