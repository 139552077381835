import React, { useEffect, useRef, useState } from "react";
import QuestionCard from "./QuestionCard";
import { useDispatch } from "react-redux";
import { evaluateAssessment } from "../../Redux/Actions/LessonActions";

const AssesmentCarousel = ({ assessmentData }) => {
    const dispatch = useDispatch();
    const [userAnswers, setUserAnswers] = useState({ ...assessmentData, Questions: [...assessmentData.Questions] });

    const handleAnswerSelection = (questionId, userAnswer) => {
        // Find the question in the userAnswers state and append user_answer
        const updatedQuestions = userAnswers.Questions.map((question) => {
            if (question.question_id === questionId) {
                return {
                    ...question,
                    user_answer: userAnswer, // Append the user answer
                };
            }
            return question;
        });

        setUserAnswers((prevState) => ({
            ...prevState,
            Questions: updatedQuestions,
        }));
    };
    useEffect(() => {
        const allAnswered = userAnswers.Questions.every((q) => q.user_answer); // Check if all questions are answered
        if (allAnswered) {
            // Dispatch the action when the last question is answered
            dispatch(evaluateAssessment(userAnswers));
        }
    }, [userAnswers])

    const carouselRef = useRef(null);
    const scrollRight = () => {
        carouselRef.current.scrollBy({ left: 358, behavior: 'smooth' });
    };

    return (
        <div
            ref={carouselRef}
            className="flex w-[390px] h-[300px] gap-[24px] overflow-x-auto scrollbar-hide"
        >
            {assessmentData.Questions.map((question, index) => (
                <div
                    key={index}
                    className="flex-shrink-0"
                >
                    <QuestionCard
                        questionNumber={index + 1}
                        totalQuestion={assessmentData.total_questions}
                        questionData={question}
                        scrollRight={scrollRight}
                        onAnswerSelect={handleAnswerSelection}
                    />
                </div>
            ))}
        </div>
    );
};

export default AssesmentCarousel;
