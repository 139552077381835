
import { BASE_URL } from '../Constants';
import { AI_BASE_URL } from '../Constants';
import axiosInstance from './AxiosInstance';

const USER_INTERRUPT_ENDPOINT = "handle-interrupt/";

export const quizData = async (session_lesson_id) => {
  const requestPayload = {
    session_lesson_id: session_lesson_id,
  };

  try {
    const response = await axiosInstance.post(`${BASE_URL}get-quiz/`, requestPayload, {
    });
    return response.data;
  } catch (error) {
    console.error("Error while making API call:", error);
    return null;
  }
};

export const assessmentData = async (session_lesson_id) => {
  const requestPayload = {
    session_lesson_id: session_lesson_id,
  };

  try {
    const response = await axiosInstance.post(`${BASE_URL}get-assessment/`, requestPayload, {
    });
    return response.data;
  } catch (error) {
    console.error("Error while making API call:", error);
    return null;
  }
};

export const sendInterruptRequest = async (requestPayload) => {
    try {
      const response = await axiosInstance.post(`${BASE_URL}${USER_INTERRUPT_ENDPOINT}`, requestPayload, {
      });
      if (response.data.status === true) {
        const data = response.data;
        const binaryData = new TextEncoder().encode(data.result.streaming_text);
        const decoder = new TextDecoder("utf-8");
        const correctedText = decoder.decode(binaryData);
        return correctedText; 
      } else {
        console.error("Failed to fetch the API response");
        return null;
      }
    } catch (error) {
      console.error("Error while making API call:", error);
      throw error;
    }
  };


  export const getLessonRecap = async (sessionLessonId) => {
    const requestPayload = {
      session_lesson_id: sessionLessonId,
    };
    try {
      const response = await axiosInstance.post(`${BASE_URL}get-lesson-recap/`, requestPayload, {
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching lesson recap:", error);
      throw error;
    }
  };
  
  export const getAIRecap = async (dataResult) => {
    try {
      const AIresponse = await axiosInstance.post(`${AI_BASE_URL}get_lesson_recap`, dataResult, {
      });
      return AIresponse;
    } catch (error) {
      console.error("Error fetching AI recap:", error);
      throw error;
    }
  };
  
  export const getSessionRecap = async (sessionId) => {
    const requestPayload = {
      session_id: sessionId,
    };
    try {
      const response = await axiosInstance.post(`${BASE_URL}get-session-recap/`, requestPayload, {
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching session recap:", error);
      throw error;
    }
  };
 

  