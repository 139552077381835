import React, { useState, useEffect, useRef } from "react";
import "./Avatar.css";
import { WEB_SOCKET } from "../../Constants";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { startLessonDelivery, endLessonDelivery } from "../../Redux/Reducers/lessonSlice";
import { endModal } from "../../Redux/Reducers/LeaveAvatarSlice";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import QuizCard from "./QuizCard";
import SpeechRecognitionLib from 'annyang';
import { AVATAR_SDK_KEY } from "../../Constants";
import { AVATAR_SDK_SCRIPT } from '../../Constants';
import MessageBubble from "../../UIComponents/MessageBubble";
import { quizData, assessmentData } from "../../Services/AvatarScreenServices";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { sendInterruptRequest } from "../../Services/AvatarScreenServices";
import { getLessonRecap, getSessionRecap, getAIRecap } from "../../Services/AvatarScreenServices";
import StatusLabel from "./AvatarScreenComponents/StatusLabel";
import WarningLabel from "./AvatarScreenComponents/WarningLabel";
import BeginLessonModal from "./AvatarScreenModals/BeginLessonModal";
import LeaveLessonConfirmModal from "./AvatarScreenModals/LeaveLessonConfirmModal";
import LessonCompleteModal from "./AvatarScreenModals/LessonCompleteModal";
import RecapPreparingModal from "./AvatarScreenModals/RecapPreparingModal";
import ResumeLessonModal from "./AvatarScreenModals/ResumeLessonModal";
import SdkErrorModal from "./AvatarScreenModals/SdkErrorModal";
import AssesmentCarousel from "./AssessmentCarousel";
import AssesmentResultModal from "./AssessmentResultModal";
import TakeOrSkipAssessmentModal from "./AvatarScreenModals/TakeOrSkipAssessmentModal";

const AvatarScreen = ({
  lessonData,
  teacherData,
  session_lesson_id,
  lessonContent,
  chatHistory,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { session_id, teacher_id, lesson_id } = useParams();
  //constants
  const INTERRUPT_PHRASE_1 = "hi teacher";
  const INTERRUPT_PHRASE_2 = "high teacher";
  const INTERRUPT_PHRASE_3 = "hey teacher";
  const VOICE_ACTIVATED = "ACTIVATE_VOICE";
  const QUIZ = "quiz"
  const ASSESSMENT = "assessment";
  const SESSION_RECAP = "Session";
  const LESSON_RECAP = "Lesson";
  const USER_INTERRUPT_QUERY_ID = "66d1e50ddc0dd3da60afc0df";
  const AVATAR_INTERRUPT_QUERY_ID = "66d1e51adc0dd3da60afc0e0";
  const NO_SPEECH_RESPONSE = "I could not hear you, please speak again";
  const MESSAGE_TYPE_FLASHCARD = 7;
  const MESSAGE_TYPE_USER = 1;
  const MESSAGE_TYPE_AVATAR = 2;
  const MESSAGE_TYPE_HISTORY_USER = 3;
  const MESSAGE_TYPE_HISTORY_AVATAR = 4;
  const MESSAGE_TYPE_LECTURE_STATUS = 5;
  const MESSAGE_TYPE_ERROR = 6;
  const LECTURE_RESUME_DELAY = 5000;
  const AVATAR_TRANSITION_DELAY = 2000;
  const CONTENT_TYPE_QUESTION = "question";
  const currentOrder = lessonContent.lesson.order;
  const progress = lessonContent.progress;
  const CONTENT_TYPE_TEXT = "text";
  const CONTENT_TYPE_FLASH_CARD = "flashcard";
  const TERMINAL_MESSAGE = "RESPONSE_IS_ENDED";
  const SDK_DISCONNECTED = "WORKER_DISCONNECTED";
  const SDK_TIMEOUT = "DISABLED_TIME_OUT";
  const NOT_ALLOWED = "NotAllowedError";
  const PERMISSION_DENIED = "PermissionDeniedError";
  const PAUSE_STATUS_MESSAGE = "Lecture Paused";
  const RESUME_STATUS_MESSAGE = "Lecture Resumed";
  const WAITING_STATUS_MESSAGE = "Awaiting response...";
  const ACTION_CONTINUE_LESSON = "continue_lesson";
  const ACTION_LESSON_RECAP = "lesson_recap";
  const ACTION_SESSION_RECAP = "session_recap";
  const ACTION_STAY = "stay";
  const ACTION_LEAVE = "leave";
  const ACTION_LESSON_RETAKE = "lesson_retake";
  const ACTION_LESSON_ASSESSMENT = "take_assessment";
  const ACTION_NEXT_LESSON = "next_lesson";
  const lessonContentTextData = lessonData.content;
  const assessmentResult = useSelector((state) => state.evaluate_assessment_reducer.evaluateAssessment);
  const socket = new WebSocket(`${WEB_SOCKET}progress-update/`);
  //state variables
  const [lessonComplete, setLessonComplete] = useState(lessonContent.is_completed);
  const [showFlashCard, setShowFlashCard] = useState(false);
  const [flashCardContent, setFlashCardContent] = useState("");
  const [pauseContentDelivery, setpauseContentDelivery] = useState(false);
  const [contentDelivered, setContentDelivered] = useState(false);
  const [sdkErrorOccured, setsdkErrorOccured] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(
   lessonContent.is_completed === false ? lessonContent.consumed_length : 0
  );
  const [microphoneAllowed, setmicrophoneAllowed] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [flashIndex, setflashIndex] = useState(0);
  const [showStart, setshowStart] = useState(false);
  const [showkeyboard, setshowkeyboard] = useState(false);
  const [isAvatarTalking, setisAvatarTalking] = useState(false);
  const [FlashPath, setFlashPath] = useState("");
  const [lessonPause, setlessonPause] = useState(false);
  const [hidePauseButton, sethidePauseButton] = useState(false);
  const [loadRecap, setloadRecap] = useState(false);
  const [animationData, setAnimationData] = useState(null);
  const [recaping, setrecaping] = useState(false);
  const [showQuizCard, setShowQuizCard] = useState(false);
  const [showSkipInterruptForNoSpeech, setshowSkipInterruptForNoSpeech] = useState(false);
  const [showTakeOrSkipAssessment, setshowTakeOrSkipAssessment] = useState(false);
  const [showAssessmentComponent, setShowAssessmentComponent] = useState(false);
  const [showAssessmentResultModal, setshowAssessmentResultModal] = useState(false);
  const [quizContent, setQuizContent] = useState(null);
  const [assessmentContent, setAssessmentContent] = useState(null);
  const [isAvatarAnswering, setisAvatarAnswering] = useState(false);
  const [messages, setMessages] = useState([]);
  const messageContainerRef = useRef(null);
  const voiceInterruptIdentifierRef = useRef(true);
  const payloadForInterruptRef = useRef(null);
  const contentOfRecapRef = useRef(null);
  const answerAvatarUsingChat = useRef(false);
  const avatarQuestion = useRef("");
  const Modal = useSelector((state) => state.AvatarModal.showModal);
  const Path = useSelector((state) => state.AvatarModal.path);
  const user = JSON.parse(localStorage.getItem("user"));
  const fullName = user.user.first_name + " " + user.user.last_name;
  //variables
  let interruptPause = false;
  
  useEffect(() => {
    fetch("/voice_waves.json")
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) =>
        console.error("Error loading Lottie animation:", error)
      );
  }, []);
  const sendProgressUpdate = (isCompleted) => {
    if (lessonComplete === false) {
      socket.onopen = () => {
        const progressData = {
          session_lesson_id: session_lesson_id,
          is_completed: isCompleted,
          consumed_length: currentIndex,
          session_id: session_id,
        };

        socket.send(JSON.stringify(progressData));
        console.log("Progress update sent:", progressData);
      };
    }
  };

  const checkMicrophoneAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setmicrophoneAllowed(true);
    } catch (error) {
      console.log(error);
      if (error.name === NOT_ALLOWED || error.name === PERMISSION_DENIED) {
        setmicrophoneAllowed(false);
      } else {
        console.error("An error occurred while checking microphone access:", error);
        setmicrophoneAllowed(false);
        showErrorToaster(error);
      }
    }
  };

  const deleteRecentStatusMessage = () => {
    setMessages(prevMessages => {
      const lastIndex = prevMessages.map(message => message.type).lastIndexOf(MESSAGE_TYPE_LECTURE_STATUS);

      if (lastIndex !== -1) {
        return [
          ...prevMessages.slice(0, lastIndex),
          ...prevMessages.slice(lastIndex + 1)
        ];
      }
      return prevMessages;
    });
  };

  const reAddMessageWithoutAnimation = () => {
    let messageText = null;
    setMessages(prevMessages => {
      const lastIndex = prevMessages.map(message => message.type).lastIndexOf(MESSAGE_TYPE_AVATAR);
      if (lastIndex !== -1) {
        messageText = prevMessages[lastIndex].text;
        return [
          ...prevMessages.slice(0, lastIndex),
          ...prevMessages.slice(lastIndex + 1)
        ];
      }
      return prevMessages;
    });

    setMessages(prevMessages => {
      if (messageText != null) {
        const text = messageText;
        const type = MESSAGE_TYPE_HISTORY_AVATAR;
        const newMessage = { text, type };
        return [...prevMessages, newMessage];
      }
      return prevMessages;
    });
  };

  const handleRetake = () => {
    setCurrentIndex(0);
    executeLesson();
  };
  const closeLeaveLessonConfirmModal = () => {
    voiceInterruptIdentifierRef.current = true;
    if(!showSkipInterruptForNoSpeech)
    {
      resumeLesson();
      startListeningForVoiceInterrupt();
    }
    dispatch(endModal());
  };

  const reloadPage = () => {
    setsdkErrorOccured(false);
    window.location.reload();
  };

  const navigateTo = () => {
    dispatch(endLessonDelivery());
    voiceInterruptIdentifierRef.current = false;
    dispatch(endModal());
    window.KlleonChat.close();
    navigate(`/app/${Path}`);
    window.location.reload();
  };

  useEffect(() => {
    if (Modal) {
      voiceInterruptIdentifierRef.current = false;
      if(!showSkipInterruptForNoSpeech)
      {
        pauseLesson();
      }
    }
  }, [Modal]);

  useEffect(() => {
    if (showAssessmentComponent && assessmentResult.result) {
      setShowAssessmentComponent(false);
      setshowAssessmentResultModal(true);
    }
  }, [showAssessmentComponent, assessmentResult]);

  const resetScreen = () => {
    window.KlleonChat.stopEcho();
    SpeechRecognitionLib.abort();
    setShowFlashCard(false);
    setFlashCardContent("");
    setpauseContentDelivery(false);
    setContentDelivered(false);
    setCurrentIndex(lessonComplete === false ? lessonContent.consumed_length : 0);
    setInputValue("");
    setIsListening(false);
    setflashIndex(0);
    setshowStart(false);
    setshowkeyboard(false);
    setisAvatarTalking(false);
    setFlashPath("");
    setlessonPause(false);
    sethidePauseButton(false);
    setloadRecap(false);
    setAnimationData(null);
    setrecaping(false);
    setShowQuizCard(false);
    setQuizContent(null);
    setisAvatarAnswering(false);
    setMessages([]);
    voiceInterruptIdentifierRef.current = true;
    setshowTakeOrSkipAssessment(false);
    dispatch(endLessonDelivery());
  };

  useEffect(() => {

    const script = document.createElement("script");
    script.src = AVATAR_SDK_SCRIPT;
    script.async = true;
    script.onload = () => {
      const sdkOption = { sdk_key: AVATAR_SDK_KEY };
      const avatarOption = {
        avatar_id: teacherData.avatar_id,
        voice_code: "en_us",
        subtitle_code: "en_us",
        voice_tts_speech_speed: 0.8,
        radius: 24,
      };
      if (window.KlleonChat) {
        window.KlleonChat.init(sdkOption);
        window.KlleonChat.showStreaming(avatarOption);
        window.KlleonChat.onChatEvent((eventData) => {
          const sdkResponse = JSON.parse(eventData);
          if (sdkResponse.chat_type === TERMINAL_MESSAGE) {
            setContentDelivered(true);  
          }
          if ( sdkResponse.chat_type === SDK_TIMEOUT) {
            resetScreen();
          }
          if ( sdkResponse.chat_type === SDK_DISCONNECTED) {
            setsdkErrorOccured(true);
            resetScreen();
          }
          if (sdkResponse.chat_type === VOICE_ACTIVATED) {
            setTimeout(() => {
              checkMicrophoneAccess();
              setshowStart(true);
              setshowkeyboard(true);
              chatHistory.forEach((message) => {
                const text = message.content;
                const type = message.type;

                if (type === MESSAGE_TYPE_AVATAR) {
                  const type = MESSAGE_TYPE_HISTORY_AVATAR;
                  const newMessage = { text, type };
                  setMessages((prevMessages) => [...prevMessages, newMessage]);
                } else if (type === MESSAGE_TYPE_USER) {
                  const type = MESSAGE_TYPE_HISTORY_USER;
                  const newMessage = { text, type };
                  setMessages((prevMessages) => [...prevMessages, newMessage]);
                }
                else if(type === 3)
                {
                  const type = MESSAGE_TYPE_FLASHCARD;
                  const newMessage = { text, type };
                  setMessages((prevMessages) => [...prevMessages, newMessage]);
                }
                document.title = 'Knnect AI';
              });
            }, AVATAR_TRANSITION_DELAY);
          }
        });
      } else {
        console.error("KlleonChat is not defined after script load.");
        setsdkErrorOccured(true);
        resetScreen();
      }
    };
    script.onerror = (error) => {
      console.error("Error loading Klleon Chat SDK script:", error);
      setsdkErrorOccured(true);
      resetScreen();
    };
    document.body.appendChild(script);
    socket.onopen = () => {
      console.log("WebSocket connection established with the server.");
    };

    socket.onmessage = (event) => {
      console.log("Message from server:", event.data);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      showErrorToaster(error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (!pauseContentDelivery && contentDelivered) {
      callNextLine();
    }
  }, [pauseContentDelivery, contentDelivered]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleWordTyped = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  const showErrorToaster = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  useEffect(() => {
    let intervalId;
    if (!lessonPause || (isAvatarTalking && lessonPause)) {
      intervalId = setInterval(handleWordTyped, AVATAR_TRANSITION_DELAY);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [lessonPause]);

  const handleSkipAndNextLesson = () => {
    handleLessonChange();
  }

  const handleLessonChange = () => {
    const nextLesson = lessonContent.all_lessons.find(
      (lesson) => lesson.order === currentOrder + 1
    );

    const nextLessonId = nextLesson ? nextLesson._id : null;
    if (nextLessonId) {
      dispatch(endModal());
      window.KlleonChat.close();
      setIsListening(false);
      setContentDelivered(false);
      setTimeout(() => {
        navigate(
          `/app/my-learning/enrolled-course/my-lesson/${session_id}/${teacher_id}/${nextLessonId}`
        );
        window.location.reload();
      }, 1000);
    } else {
      console.log("No next lesson found.");
      showErrorToaster("No next lesson found.");
    }
  };

  const executeLesson = () => {
    dispatch(startLessonDelivery());
    setshowStart(false);
    startListeningForVoiceInterrupt();
    if (!pauseContentDelivery && currentIndex < lessonContentTextData.length) {
      if (window.KlleonChat) {
        if (
          lessonContentTextData[currentIndex].type === CONTENT_TYPE_FLASH_CARD
        ) {
          sendProgressUpdate(false);
          setFlashCardContent(lessonContentTextData[currentIndex].path);
          setFlashPath(lessonContentTextData[currentIndex].path);
          setflashIndex(currentIndex);
          setTimeout(() => {
            setShowFlashCard(true);
          }, 1000);
          setContentDelivered(false);
          setTimeout(() => {
            setContentDelivered(true);
          }, 1000);
        } else if (lessonContentTextData[currentIndex].type === QUIZ) {
          sendProgressUpdate(false);
          setCurrentIndex(currentIndex + 1);
          callQuiz();
        } else if (lessonContentTextData[currentIndex].type === ASSESSMENT) {
          sendProgressUpdate(true);
          setshowTakeOrSkipAssessment(true);
        } else if (lessonContentTextData[currentIndex].type === CONTENT_TYPE_TEXT)
        {
          sendProgressUpdate(false);
          window.KlleonChat.echo(lessonContentTextData[currentIndex].content);
          addMessageToChat(
            lessonContentTextData[currentIndex].content,
            MESSAGE_TYPE_AVATAR
          );
          setCurrentIndex(currentIndex + 1);
          setContentDelivered(false);
        }
        else if(lessonContentTextData[currentIndex].type === CONTENT_TYPE_QUESTION)
        {
          setpauseContentDelivery(true);
          sendProgressUpdate(false);
          sethidePauseButton(true);
          setTimeout(() => {
            let isTerminalMessage = true;
            window.KlleonChat.echo(lessonContentTextData[currentIndex].content);
            if (flashIndex + 2 === currentIndex) {
              setShowFlashCard(false);
              setflashIndex(0);
            }
            addMessageToChat(
              lessonContentTextData[currentIndex].content,
              MESSAGE_TYPE_AVATAR
            );
            window.KlleonChat.onChatEvent((eventData) => {
              const sdkResponse = JSON.parse(eventData);
              if (
                sdkResponse.chat_type === TERMINAL_MESSAGE &&
                isTerminalMessage === true
              ) {
                isTerminalMessage = false;
                SpeechRecognitionLib.abort();
                setTimeout(() => {
                  if (interruptPause === false) {
                    sethidePauseButton(true);
                    setisAvatarTalking(true);
                    handleAvatarQuestion(lessonContentTextData[currentIndex].content);
                    setCurrentIndex(currentIndex + 1);
                  }
                }, 2000);
              }
            });
          }, 2000);

        }
      } else {
        console.error("KlleonChat is not defined.");
        showErrorToaster("KlleonChat is not defined.");
      }
    } else {
      console.log("All messages have been processed or lesson is paused.");
    }
  };

  const addMessageToChat = (text, type) => {
    if (type === 2) {
      const newMessage = { text, type };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    } else {
      const newMessage = { text, type };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    }
  };

  const callNextLine = () => {
    if (pauseContentDelivery) {
      console.log("Lesson is paused, exiting callNextLine");
      return;
    }

    if (contentDelivered) {
      if (currentIndex < lessonContentTextData.length) {
        const currentLessonObject = lessonContentTextData[currentIndex];
        setCurrentIndex(currentIndex + 1);
        if (currentLessonObject.type === CONTENT_TYPE_TEXT) {
          sendProgressUpdate(false);
          setContentDelivered(false);
          window.KlleonChat.echo(currentLessonObject.content);
          if (flashIndex + 2 === currentIndex) {
            setShowFlashCard(false);
            setflashIndex(0);
          }
          addMessageToChat(
            currentLessonObject.content,
            MESSAGE_TYPE_AVATAR
          );
        } else if (currentLessonObject.type === CONTENT_TYPE_QUESTION) {
          sendProgressUpdate(false);
          sethidePauseButton(true);
          setTimeout(() => {
            let isTerminalMessage = true;
            window.KlleonChat.echo(currentLessonObject.content);
            if (flashIndex + 2 === currentIndex) {
              setShowFlashCard(false);
              setflashIndex(0);
            }
            addMessageToChat(
              currentLessonObject.content,
              MESSAGE_TYPE_AVATAR
            );
            window.KlleonChat.onChatEvent((eventData) => {
              const sdkResponse = JSON.parse(eventData);
              if (
                sdkResponse.chat_type === TERMINAL_MESSAGE &&
                isTerminalMessage === true
              ) {
                isTerminalMessage = false;
                SpeechRecognitionLib.abort();
                setTimeout(() => {
                  if (interruptPause === false) {
                    sethidePauseButton(true);
                    setisAvatarTalking(true);
                    handleAvatarQuestion(currentLessonObject.content);
                  }
                }, 2000);
              }
            });
          }, 2000);
        } else if (currentLessonObject.type === CONTENT_TYPE_FLASH_CARD) {
          sendProgressUpdate(false);
          setFlashPath(currentLessonObject.path);
          setFlashCardContent(currentLessonObject.path);
          setflashIndex(currentIndex);
          setTimeout(() => {
            setShowFlashCard(true);
          }, 1000);
          setContentDelivered(false);
          setTimeout(() => {
            setContentDelivered(true);
          }, 1000);
        } else if (lessonContentTextData[currentIndex].type === QUIZ) {
          sendProgressUpdate(false);
          callQuiz();
        } 
        else if (lessonContentTextData[currentIndex].type === ASSESSMENT) {
          sendProgressUpdate(true);
          setshowTakeOrSkipAssessment(true);
        }
      
      } else {
        setLessonComplete(true);
        sendProgressUpdate(true);
        resetScreen();
        setshowStart(true);
        setshowkeyboard(true);
        chatHistory.forEach((message) => {
          const text = message.content;
          const type = message.type;

          if (type === MESSAGE_TYPE_AVATAR) {
            const type = MESSAGE_TYPE_HISTORY_AVATAR;
            const newMessage = { text, type };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
          } else if (type === MESSAGE_TYPE_USER) {
            const type = MESSAGE_TYPE_HISTORY_USER;
            const newMessage = { text, type };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
          }
        });
      }
    }
  };

  const callQuiz = async () => {
    const text = WAITING_STATUS_MESSAGE;
    const type = MESSAGE_TYPE_LECTURE_STATUS;
    const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    try {
      const data = await quizData(session_lesson_id);

    if (data) {
      setQuizContent(data.result.Questions[0]);
      showQuiz();
      setlessonPause(true);
      interruptPause = true;
      setpauseContentDelivery(true);
      window.KlleonChat.stopEcho();
      setIsListening(false);
    } else {
      setContentDelivered(false);
      showErrorToaster("An error occured while retrieving quiz");
      console.error("An error occured while retrieving quiz");
      setTimeout(() => {
        setContentDelivered(true);
      }, 1000);
       
    }
  } catch (error) {
    setContentDelivered(false);
    console.error("Error in callQuiz:", error);
    const text = error; 
    const type = MESSAGE_TYPE_ERROR;
    const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setTimeout(() => {
      setContentDelivered(true);
    }, 1000);
  }
};

const getAssessment = async () => {
  voiceInterruptIdentifierRef.current = false;
  SpeechRecognitionLib.abort();
  setshowTakeOrSkipAssessment(false);
  const text = WAITING_STATUS_MESSAGE;
  const type = MESSAGE_TYPE_LECTURE_STATUS;
  const newMessage = { text, type };
  setMessages((prevMessages) => [...prevMessages, newMessage]);
  try {
    const data = await assessmentData(session_lesson_id);

  if (data && data.result) {
    setAssessmentContent(data.result);
    showAssessment();
    setlessonPause(true);
    interruptPause = true;
    setpauseContentDelivery(true);
    window.KlleonChat.stopEcho();
    setIsListening(false);
  } else {
    setContentDelivered(false);
    console.error("An error occured while retrieving assessment");
    showErrorToaster("An error occured while retrieving assessment");
    setContentDelivered(true); 
  }
} catch (error) {
  setContentDelivered(false);
  console.error("Error in callAssessment:", error);
  const text = error; 
  const type = MESSAGE_TYPE_ERROR;
  const newMessage = { text, type };
  setMessages((prevMessages) => [...prevMessages, newMessage]);
  setContentDelivered(true);
}
};
  const showFC = (flashCardContent) => {
    return (
      <div className="absolute top-[8%] left-[5%] w-[160px] h-[208px]">
        <img
          src={flashCardContent}
          alt="Flash Card"
          className={`flex items-center justify-center transition-all ${showFlashCard
              ? "duration-500 opacity-100 scale-100"
              : "duration-[5000ms] opacity-0 scale-50"
            }`}
        />
      </div>
    );
  };

  const showQuiz = () => {
    setShowQuizCard(true);
  };

  const showAssessment = () => {
    setShowAssessmentComponent(true);
  };

  const handleStopListening = () => {
    SpeechRecognitionLib.abort();
  };

  const pauseLesson = () => {
    interruptPause = true;
    setpauseContentDelivery(true);
    window.KlleonChat.stopEcho();
    setIsListening(false);
    reAddMessageWithoutAnimation();
    const text = PAUSE_STATUS_MESSAGE;
    const type = MESSAGE_TYPE_LECTURE_STATUS;
    const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const resumeLesson = () => {
    setshowSkipInterruptForNoSpeech(false);
    const text = RESUME_STATUS_MESSAGE;
    const type = MESSAGE_TYPE_LECTURE_STATUS;
    const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setShowQuizCard(false);
    setlessonPause(false);
    interruptPause = false;
    setpauseContentDelivery(false);
    setContentDelivered(true);
  };

  const resumeAfterSkipNoSpeechInterrupt = () => {
    setshowSkipInterruptForNoSpeech(false);
    setContentDelivered(false);
    setTimeout(() => {
      setlessonPause(false);
      interruptPause = false;
      sethidePauseButton(false);
      setpauseContentDelivery(false);
      setContentDelivered(true);
      startListeningForVoiceInterrupt();
      setisAvatarTalking(false);
    }, AVATAR_TRANSITION_DELAY);
    
  };

  const resumeFromChat = () =>
  {
      setlessonPause(false);
      interruptPause = false;
      sethidePauseButton(false);
      setpauseContentDelivery(false);
      startListeningForVoiceInterrupt();
      setisAvatarTalking(false);
  }

  const retryApiFromChat = async () => {
    await CallInterruptAPI();
  }

  const CallInterruptAPI = async () => {
    const text = WAITING_STATUS_MESSAGE;
    const type = MESSAGE_TYPE_LECTURE_STATUS;
    const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    try {
      const correctedText = await sendInterruptRequest(payloadForInterruptRef.current);
      deleteRecentStatusMessage();
      if (correctedText) {
        let isTerminalMessage = true;
        window.KlleonChat.echo(correctedText);
        addMessageToChat(correctedText, MESSAGE_TYPE_AVATAR);
        setTimeout(() => {
          setisAvatarAnswering(true);
        }, AVATAR_TRANSITION_DELAY);

        window.KlleonChat.onChatEvent((eventData) => {
          const sdkResponse = JSON.parse(eventData);
          if (sdkResponse.chat_type === TERMINAL_MESSAGE && isTerminalMessage === true) {
            setisAvatarAnswering(false);
            isTerminalMessage = false;
            const text = RESUME_STATUS_MESSAGE;
            const type = MESSAGE_TYPE_LECTURE_STATUS;
            const newMessage = { text, type };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            if (voiceInterruptIdentifierRef.current === false) {
              voiceInterruptIdentifierRef.current = true;
            }
            setTimeout(() => {
              setlessonPause(false);
              interruptPause = false;
              sethidePauseButton(false);
              setpauseContentDelivery(false);
              startListeningForVoiceInterrupt();
              setisAvatarTalking(false);
            }, AVATAR_TRANSITION_DELAY);
          }
        });
      }
      else {
        const text = "An error occured when fetching response";
        const type = MESSAGE_TYPE_ERROR;
        const newMessage = { text, type };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    } catch (error) {
      console.error("Error in handleInterrupt:", error);
      const text = error;
      const type = MESSAGE_TYPE_ERROR;
      const newMessage = { text, type };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    }
  }

  const resumeLessonAfterQuiz = () => {
    deleteRecentStatusMessage();
    resumeLesson();
    startListeningForVoiceInterrupt();
  };
  const handleVerbalSelection = (AvatarResponse) => {
    deleteRecentStatusMessage();
    let isTerminalMessage = true;
    setShowQuizCard(false);
    window.KlleonChat.echo(AvatarResponse);
    addMessageToChat(AvatarResponse, MESSAGE_TYPE_AVATAR);
    window.KlleonChat.onChatEvent((eventData) => {
      const sdkResponse = JSON.parse(eventData);
      if (
        sdkResponse.chat_type === TERMINAL_MESSAGE &&
        isTerminalMessage === true
      ) {
        isTerminalMessage = false;
        setTimeout(() => {
          setlessonPause(false);
          interruptPause = false;
          setpauseContentDelivery(false);
          setContentDelivered(true);
          startListeningForVoiceInterrupt();
        }, AVATAR_TRANSITION_DELAY);
      }
    });
  };

  const handleUserInput = async () => {
    setshowSkipInterruptForNoSpeech(false);
    sethidePauseButton(true);
    setisAvatarTalking(true);
    setlessonPause(true);
    voiceInterruptIdentifierRef.current = false;
    pauseLesson();
    if (inputValue.trim() !== "") {
      addMessageToChat(inputValue, MESSAGE_TYPE_USER);
      setInputValue("");
      let requestPayload;
      if(answerAvatarUsingChat.current === true)
      {
        requestPayload = {
          user_name: fullName,
          query: avatarQuestion.current,
          answer: inputValue,
          query_type_id: AVATAR_INTERRUPT_QUERY_ID,
          consumed_length_query: currentIndex,
          lesson_id: lesson_id,
          session_id: session_id,
          teacher_id: teacher_id,
          flashcard: FlashPath,
        };
      }
      else
      {
         requestPayload = {
          user_name: fullName,
          query: inputValue,
          query_type_id: USER_INTERRUPT_QUERY_ID,
          consumed_length_query: currentIndex,
          lesson_id: lesson_id,
          session_id: session_id,
          teacher_id: teacher_id,
        };
      }
      payloadForInterruptRef.current = requestPayload;
      avatarQuestion.current = "";
      answerAvatarUsingChat.current = false;
      CallInterruptAPI(); 
    }
  };

  let noResponseCounter = 0;
  let queryAnswered = false;
  const handleSpeechRecognition = async () => {
    noResponseCounter = 0;
    setisAvatarTalking(true);
    setIsListening(true);
    setInputValue("");
    if (SpeechRecognitionLib) {
      let finalQuery = "";
      queryAnswered = false;
      SpeechRecognitionLib.removeCallback();

      const processSpeechResult = (speechResult) => {
        setInputValue(speechResult);
        if (speechResult.trim() !== "") {
          queryAnswered = true;
          finalQuery = speechResult;
          SpeechRecognitionLib.abort();
          addMessageToChat(finalQuery, MESSAGE_TYPE_USER);
          setInputValue("");
          const requestPayload = {
            user_name: fullName,
            query: finalQuery,
            query_type_id: USER_INTERRUPT_QUERY_ID,
            consumed_length_query: currentIndex,
            lesson_id: lesson_id,
            session_id: session_id,
            teacher_id: teacher_id,
          };

          payloadForInterruptRef.current = requestPayload;
          CallInterruptAPI();
        }
      };
      SpeechRecognitionLib.start({ continuous: true });
      SpeechRecognitionLib.addCallback("start", () => {
        console.log("Speech recognition started. Please speak...");
        setIsListening(true);
      });
      SpeechRecognitionLib.addCallback("result", (speechResult) => {
        processSpeechResult(speechResult[0]);
      });
      SpeechRecognitionLib.addCallback("error", (event) => {
        console.error("Speech recognition error:", event);
      });
      SpeechRecognitionLib.addCallback("end", () => {
        setIsListening(false);
        console.log("Speech recognition ended.");
        if (noResponseCounter < 3 && queryAnswered === false) {
          SpeechRecognitionLib.abort();
          window.KlleonChat.echo(NO_SPEECH_RESPONSE);
          addMessageToChat(NO_SPEECH_RESPONSE, MESSAGE_TYPE_AVATAR);
          setTimeout(() => {
            noResponseCounter++;
            SpeechRecognitionLib.start({ continuous: true });
          }, LECTURE_RESUME_DELAY);
        } else if (noResponseCounter >= 3 && queryAnswered === false) {
          SpeechRecognitionLib.abort();
          const text = RESUME_STATUS_MESSAGE; 
          const type = MESSAGE_TYPE_LECTURE_STATUS;
          const newMessage = { text, type };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setTimeout(() => {
            setisAvatarTalking(false);
            setlessonPause(false);
            interruptPause = false;
            sethidePauseButton(false);
            setpauseContentDelivery(false);
            SpeechRecognitionLib.abort();
            startListeningForVoiceInterrupt();
          }, AVATAR_TRANSITION_DELAY);
        }
      });
    } else {
      console.error("Speech recognition not supported in this browser.");
    }
  };

  const startListeningForVoiceInterrupt = async () => {
    if (SpeechRecognitionLib && microphoneAllowed) {
      SpeechRecognitionLib.removeCallback();
      SpeechRecognitionLib.setLanguage("en-US");
      SpeechRecognitionLib.continuous = true;
      SpeechRecognitionLib.interimResults = false;
      SpeechRecognitionLib.addCallback("start", () => {
        console.log("Speech recognition started...");
        voiceInterruptIdentifierRef.current = true;
      });
      SpeechRecognitionLib.addCallback("result", (event) => {
        const transcript = event[0].trim();
        console.log("User said: ", transcript);
        if (transcript.toLowerCase().includes(INTERRUPT_PHRASE_1) || transcript.toLowerCase().includes(INTERRUPT_PHRASE_2) || transcript.toLowerCase().includes(INTERRUPT_PHRASE_3)) {
          console.log("Detected 'Hi Teacher'. Stopping speech recognition.");
          voiceInterruptIdentifierRef.current = false;
          SpeechRecognitionLib.abort();
          setlessonPause(true);
          pauseLesson();
          window.KlleonChat.echo("Yes, how can I help you?");
          addMessageToChat("Yes, how can I help you?", 2);

          noResponseCounter = 0;
          sethidePauseButton(true);
          setTimeout(() => {
            handleSpeechRecognition();
          }, LECTURE_RESUME_DELAY);
        }
      });
      SpeechRecognitionLib.addCallback("error", (event) => {
        console.error("Speech recognition error:", event);
      });

      SpeechRecognitionLib.addCallback("end", () => {
        if (voiceInterruptIdentifierRef.current) {
          console.log("Restarting speech recognition...");
          SpeechRecognitionLib.start({ continuous: true });
        }
      });
      SpeechRecognitionLib.start({ continuous: true });
    } else {
      console.error("Speech recognition not supported in this browser.");
      setmicrophoneAllowed(false);
    }
  };

  const handleSpeech = () => {
    voiceInterruptIdentifierRef.current = false;
    sethidePauseButton(true);
    setlessonPause(true);
    pauseLesson();
    setTimeout(() => {
      handleSpeechRecognition();
    }, AVATAR_TRANSITION_DELAY);
  };

  const handleAvatarQuestion = async (question) => {
    if (!microphoneAllowed) {
      sethidePauseButton(false);
      let isTerminalMessage = true;
      answerAvatarUsingChat.current = true;
      avatarQuestion.current = question;
      window.KlleonChat.echo("Please provide your answer using the text box below");
      addMessageToChat("Please provide your answer using the text box below", MESSAGE_TYPE_AVATAR);
      window.KlleonChat.onChatEvent((eventData) => {
        const sdkResponse = JSON.parse(eventData);
        if (sdkResponse.chat_type === TERMINAL_MESSAGE && isTerminalMessage === true) {
          isTerminalMessage = false;
          setshowSkipInterruptForNoSpeech(true);
          return;
        }
      });
    }
    else {
      voiceInterruptIdentifierRef.current = false;
      setlessonPause(true);
      pauseLesson();
      setisAvatarTalking(true);
      setIsListening(true);
      noResponseCounter = 0;
      setInputValue("");

      if (SpeechRecognitionLib) {
        let finalQuery = "";
        queryAnswered = false;
        SpeechRecognitionLib.removeCallback();

        const processSpeechResultQuestion = (speechResult) => {
          setInputValue(speechResult);

          if (speechResult.trim() !== "") {
            queryAnswered = true;
            finalQuery = speechResult;
            SpeechRecognitionLib.abort();
            addMessageToChat(finalQuery, MESSAGE_TYPE_USER);
            setInputValue("");
            const requestPayload = {
              user_name: fullName,
              query: question,
              answer: speechResult,
              query_type_id: AVATAR_INTERRUPT_QUERY_ID,
              consumed_length_query: currentIndex,
              lesson_id: lesson_id,
              session_id: session_id,
              teacher_id: teacher_id,
              flashcard: FlashPath,
            };
            payloadForInterruptRef.current = requestPayload;
            CallInterruptAPI();
          }
        };

      SpeechRecognitionLib.start({ continuous: true });
      SpeechRecognitionLib.addCallback("start", () => {
        setIsListening(true);
        console.log("Speech recognition started. Please speak...");
      });

        SpeechRecognitionLib.addCallback("result", (speechResult) => {
          processSpeechResultQuestion(speechResult[0]);
        });

        SpeechRecognitionLib.addCallback("error", (event) => {
          console.error("Speech recognition error:", event);
        });

        SpeechRecognitionLib.addCallback("end", () => {
          setIsListening(false);
          console.log("Speech recognition ended.");
          if (noResponseCounter < 3 && queryAnswered === false) {
            SpeechRecognitionLib.abort();
            window.KlleonChat.echo(NO_SPEECH_RESPONSE);
            addMessageToChat(NO_SPEECH_RESPONSE, MESSAGE_TYPE_AVATAR);
            setTimeout(() => {
              noResponseCounter++;
              SpeechRecognitionLib.start({ continuous: true });
            }, LECTURE_RESUME_DELAY);
          } else if (noResponseCounter >= 3 && queryAnswered === false) {
            SpeechRecognitionLib.abort();
            const text = RESUME_STATUS_MESSAGE;
            const type = MESSAGE_TYPE_LECTURE_STATUS;
            const newMessage = { text, type };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setTimeout(() => {
              setisAvatarTalking(false);
              setlessonPause(false);
              interruptPause = false;
              sethidePauseButton(false);
              setpauseContentDelivery(false);
              SpeechRecognitionLib.abort();
              if (voiceInterruptIdentifierRef.current === false) {
                voiceInterruptIdentifierRef.current = true;
              }
            resumeLesson();
            startListeningForVoiceInterrupt();
          }, AVATAR_TRANSITION_DELAY);
        }
      });
    } else {
      console.error("Speech recognition not supported in this browser."); 
    }
  }
  };

  const handleRecapLesson = async () => {
    contentOfRecapRef.current = LESSON_RECAP;
    sethidePauseButton(true);
    setshowStart(false);
    let completeRecap;
    try {
      setloadRecap(true);
      const data = await getLessonRecap(session_lesson_id);
      const AIresponse = await getAIRecap(data.result);
      if (AIresponse.status === 200) {
        setloadRecap(true);
        completeRecap = AIresponse.data; 
        setloadRecap(false);
        setTimeout(() => {
          setrecaping(true);
        }, AVATAR_TRANSITION_DELAY);
        let isTerminalMessage = true;
        addMessageToChat(completeRecap, MESSAGE_TYPE_AVATAR);
        window.KlleonChat.echo(completeRecap);
        window.KlleonChat.onChatEvent((eventData) => {
          const sdkResponse = JSON.parse(eventData);
          if (
            sdkResponse.chat_type === TERMINAL_MESSAGE &&
            isTerminalMessage === true
          ) {
            isTerminalMessage = false;
            setrecaping(false);
            setTimeout(() => {
              sethidePauseButton(false);
            }, AVATAR_TRANSITION_DELAY);
          }
        });
      }
    } catch (error) {
      setloadRecap(false);
      console.error("Error handling recap lesson:", error);
      showErrorToaster(error);
      sethidePauseButton(false);
    }
  };
  
  const handleRecapSession = async () => {
    contentOfRecapRef.current = SESSION_RECAP;
    sethidePauseButton(true);
    setshowStart(false);
    let completeRecap;
    try {
      setloadRecap(true);
      const data = await getSessionRecap(session_id);
      const AIresponse = await getAIRecap(data.result);
      if (AIresponse.status === 200) {
        setloadRecap(true);
        completeRecap = AIresponse.data; 
        setloadRecap(false);
        setTimeout(() => {
          setrecaping(true);
        }, AVATAR_TRANSITION_DELAY);
        let isTerminalMessage = true;
        addMessageToChat(completeRecap, MESSAGE_TYPE_AVATAR);
        window.KlleonChat.echo(completeRecap);
        window.KlleonChat.onChatEvent((eventData) => {
          const sdkResponse = JSON.parse(eventData);
          if (
            sdkResponse.chat_type === TERMINAL_MESSAGE &&
            isTerminalMessage === true
          ) {
            isTerminalMessage = false;
            setrecaping(false);
            setTimeout(() => {
              sethidePauseButton(false);
            }, AVATAR_TRANSITION_DELAY);
          }
        });
      }
    } catch (error) {
      setloadRecap(false);
      console.error("Error handling recap session:", error);
      showErrorToaster(error);
      sethidePauseButton(false);
    }
  };

  const stopRecap = () => {
    window.KlleonChat.stopEcho();
    const text = messages[messages.length - 1].text;
    setMessages(messages.slice(0, -1));
    const type = MESSAGE_TYPE_HISTORY_AVATAR;
    const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    sethidePauseButton(false);
    startListeningForVoiceInterrupt();
  };
  const skipInterrupt = () => {
    window.KlleonChat.stopEcho();
    const text = messages[messages.length - 1].text;
    setMessages(messages.slice(0, -1));
    const type = MESSAGE_TYPE_HISTORY_AVATAR;
    const newMessage = { text, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const handleResumeLessonAction = (param) => {
    switch (param) {
      case ACTION_CONTINUE_LESSON:
        executeLesson();
        break;
      case ACTION_LESSON_RECAP:
        handleRecapLesson();
        break;
      case ACTION_SESSION_RECAP:
        handleRecapSession();
        break;     
      default:
        break;
    }
  }

  const handleLessonCompleteAction = (param) => {
    switch (param) {
      case ACTION_LESSON_RETAKE:
        handleRetake();
        break;
      case ACTION_NEXT_LESSON:
        handleLessonChange();
        break;     
      default:
        break;
    }
  }
  const handleEndLessonAssessmentAction = (param) => {
    switch (param) {
      case ACTION_LESSON_ASSESSMENT:
        getAssessment();
        break;
      case ACTION_NEXT_LESSON:
        handleSkipAndNextLesson();
        break;     
      default:
        break;
    }
  }

  const handleLeaveLessonConfirm = (param) => {
    switch (param) {
      case ACTION_STAY:
        closeLeaveLessonConfirmModal();
        break;
      case ACTION_LEAVE:
        navigateTo();
        break;
      default:
        break;
    }
  }

  return (
    <div style={{ position: "relative", height: "84vh" }}>
      <div id="klleon_chat"></div>
      {showkeyboard && !lessonPause && (
        <div className="absolute top-6 right-6 flex justify-center">
          <button
            disabled={isAvatarTalking || hidePauseButton}
            onClick={pauseLesson}
          >
            <img src={isAvatarTalking || hidePauseButton? "/pause-disabled.svg" : "/pause-button.svg"} alt="" />
          </button>
        </div>
      )}

      {!microphoneAllowed  && (<WarningLabel label="Enable microphone access in site settings to talk with the teacher." />)}

      {isAvatarAnswering && (<StatusLabel label="Currently answering your query" labelAction={skipInterrupt}/>)}

      {showSkipInterruptForNoSpeech && (<StatusLabel label="Currently waiting for user answer" labelAction={resumeAfterSkipNoSpeechInterrupt}/>)}

      {showQuizCard && quizContent !== null && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-40 rounded-3xl">
          <QuizCard
            question={quizContent}
            onQuizEnd={resumeLessonAfterQuiz}
            onQuizResponse={handleVerbalSelection}
          />
        </div>
      )}

      {showTakeOrSkipAssessment === true && (
        <TakeOrSkipAssessmentModal 
          title="Hey, It seems like you have completed this lesson!"
          message="Do you want to take the lesson end assessment?"
          handleEndLessonAssessmentAction={handleEndLessonAssessmentAction} />
      )}

      {showAssessmentComponent && assessmentContent !== null && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-40 rounded-3xl">
          <AssesmentCarousel
            assessmentData={assessmentContent}
          />
        </div>
      )}

      {showAssessmentResultModal && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-40 rounded-3xl">
          <AssesmentResultModal
            assessmentResult={assessmentResult.result}
            nextLesson = {handleLessonChange}
          />
        </div>
      )}

      {showkeyboard && lessonPause && (
        <div className="absolute top-6 right-6 flex justify-center">
          <button disabled={isAvatarTalking || hidePauseButton} onClick={resumeLesson}>
          <img src={isAvatarTalking || hidePauseButton? "/play_disabled.svg" : "/play-button.svg"} alt="" />
          </button>
        </div>
      )}
      {showStart && progress === 0 && ( <BeginLessonModal message="Ready to dive in?" beginLessonAction={executeLesson}/>)}

      {loadRecap && (<RecapPreparingModal message="Loading your recap!" />)}

        {sdkErrorOccured && (
          <SdkErrorModal 
            title="OOPS!" 
            subTitle="Looks like we encountered an issue, just refresh and we’ll get you back on track!"
            sdkErrorModalAction={reloadPage} />
      )}

      {showStart && progress > 0 && progress < 100 && (  
        <ResumeLessonModal 
          title="Hey, Great to See You Again!" 
          subTitle="Need a quick refresher on what we covered last time?" 
          buttonAction={handleResumeLessonAction} />
      )}

      {showStart && lessonComplete === true && (
        <LessonCompleteModal 
          title="Hey, Great to See You Again!"
          message="It looks like you have completed this lesson. Do you want to
              retake or go to the next lesson?"
              lessonCompleteAction={handleLessonCompleteAction} />
      )}

      {Modal && (
        <LeaveLessonConfirmModal 
          title="Are you sure you want to leave?"
          message=" You are about to leave this page, leaving now will reset your
              lecture progress."
          leaveConfirmationAction={handleLeaveLessonConfirm} />   
      )}

      {showFC(flashCardContent)}
      <div
        ref={messageContainerRef}
        className="absolute bottom-[70px] w-full md:w-[40%] max-h-[50%] right-[10px] container flex flex-col p-4 h-3/5 gap-4 overflow-y-auto hide-scroll"
      >
        <div>
          {messages.length !== 0 &&
            messages.map((message, index) => (
              <MessageBubble
                key={index}
                message={message}
                index={index}
                handleWordTyped={handleWordTyped}
                onRetry={retryApiFromChat}
                onResume={resumeFromChat}
              />
            ))}
        </div>
      </div>

      {isListening && (
        <div className="absolute bottom-6 left-[40%] flex flex-col items-center">
          <Lottie
            animationData={animationData}
            className=" w-[150px] h-[150px]"
          />
          <button onClick={handleStopListening}>
            <img src="/cross-button.svg" alt="" />
          </button>
        </div>
      )}

      {recaping && (<StatusLabel label={`Currently Showing ${contentOfRecapRef.current} Recap`} labelAction={stopRecap}/>)}
        
      {showkeyboard && (
        <div className="absolute bottom-4 right-6 flex justify-center w-[90%] md:w-[38%]">
          <div className="w-[90%] mr-2">
            <input
              className="h-[38px] w-full rounded-[6px] p-4 text-[14px] font-open_sans border-none"
              type="text"
              value={inputValue}
              readOnly={hidePauseButton || lessonPause}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={
                isListening ? "Listening...." : "Type your message here..."
              }
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  !hidePauseButton && !lessonPause &&
                  inputValue !== ""
                ) {
                  handleUserInput();
                }
              }}
            />
          </div>

          {inputValue === "" ? (
            <button
              className="bg-white p-2 rounded-[6px] cursor-pointer"
              onClick={handleSpeech}
              disabled={hidePauseButton || lessonPause || !microphoneAllowed}
            >
              <img src={hidePauseButton || lessonPause || !microphoneAllowed ? "/mic-disabled.svg" : "/mic.svg"} alt="" />
            </button>
          ) : (
            <button
              className="bg-white p-2 rounded-[6px] cursor-pointer"
              onClick={handleUserInput}
              disabled={hidePauseButton || lessonPause}
            >
              <img src="/send-2.svg" alt="" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AvatarScreen;
