
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetSignIn } from '../Redux/Actions/Auth/SignInAction';

const AdminDropMenu = () => {
    const [isAdminMenuVisible, setIsAdminMenuVisible] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [sidebar, setSidebar] = useState("");
    useEffect(() => {
        setSidebar(location.pathname);
    }, [location])
    // logout function
    const handleLogout = () => {
        localStorage.removeItem("user");
        dispatch(resetSignIn());
        navigate("/");
    }
    // set selected option
    const handleSidebarClick = (path) => () => {
        if (!location.pathname.includes("admin")) {
            navigate(`admin/${path}`);
        } else {
            navigate(path);
        }
        toggleMenu();
    };
    // open menu
    const toggleMenu = () => {
        setIsAdminMenuVisible(!isAdminMenuVisible);
    }
    return (
        <div
            onClick={toggleMenu}
            className="hidden lg:flex min-w-[204px] items-center min-h-[40px] gap-[12px] cursor-pointer">
            <div className="flex flex-col gap-[2px] min-h-[38px]">
                <p className="text-neutral-1 font-poppins leading-normal text-sm text-right font-semibold">
                    {user.user.first_name} {user.user.last_name}
                </p>
                <p className="text-xs text-neutral-1 font-poppins leading-tight font-semibold">
                    {user.user.email}
                </p>
            </div>
            <img
                src={user?.user?.profile_image ? user.user.profile_image : "/avatar.png"}
                alt="profile"
                className='h-[40px] w-[40px] rounded-full'
            />
            <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.41444 0.535507L1.41443 0.535524L1.41725 0.538277L6.76725 5.76328L7.00126 5.99182L7.23418 5.76216L12.5842 0.487165L12.5842 0.487171L12.5858 0.485507C12.6807 0.390681 12.8196 0.390682 12.9144 0.485507C13.009 0.580053 13.0093 0.718458 12.9153 0.813263C12.915 0.813543 12.9147 0.813823 12.9144 0.814102L7.16652 6.46204L7.16651 6.46203L7.16444 6.4641C7.06813 6.56041 7.02327 6.56647 7.00015 6.56647C6.94122 6.56647 6.89018 6.55203 6.8204 6.4989L1.08502 0.863269C0.991024 0.768465 0.991301 0.630055 1.08585 0.535507C1.18067 0.440682 1.31962 0.440682 1.41444 0.535507Z" fill="white" stroke="white" stroke-width="2" />
            </svg>
            {isAdminMenuVisible && (
                <div
                    className="absolute right-[center] top-20 w-[200px] bg-white shadow-sm border rounded-[6px] overflow-hidden transition-all ease-in-out duration-300 z-[100]"
                >
                    <nav className="w-full py-[12px] flex flex-col gap-[24px]">
                        <ul className="flex flex-col gap-2 items-center">
                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[10px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-300 hover:gap-4 hover:text-sidebar-black
${sidebar.startsWith("/admin/dashboard") ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'}
${isAdminMenuVisible ? 'opacity-100' : 'opacity-0'}
`}
                                onClick={handleSidebarClick("dashboard")}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="transition-colors duration-300 ease-in-out"
                                    >
                                        <path
                                            d="M14.1663 11.6663V16.6663M11.6663 14.1663H16.6663M4.99967 8.33301H6.66634C7.58682 8.33301 8.33301 7.58682 8.33301 6.66634V4.99967C8.33301 4.0792 7.58682 3.33301 6.66634 3.33301H4.99967C4.0792 3.33301 3.33301 4.0792 3.33301 4.99967V6.66634C3.33301 7.58682 4.0792 8.33301 4.99967 8.33301ZM13.333 8.33301H14.9997C15.9201 8.33301 16.6663 7.58682 16.6663 6.66634V4.99967C16.6663 4.0792 15.9201 3.33301 14.9997 3.33301H13.333C12.4125 3.33301 11.6663 4.0792 11.6663 4.99967V6.66634C11.6663 7.58682 12.4125 8.33301 13.333 8.33301ZM4.99967 16.6663H6.66634C7.58682 16.6663 8.33301 15.9201 8.33301 14.9997V13.333C8.33301 12.4125 7.58682 11.6663 6.66634 11.6663H4.99967C4.0792 11.6663 3.33301 12.4125 3.33301 13.333V14.9997C3.33301 15.9201 4.0792 16.6663 4.99967 16.6663Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>

                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out`}
                                >
                                    Dashboard
                                </span>
                            </li>

                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-300 hover:gap-4 hover:text-sidebar-black
                     } ${sidebar === "/admin/profile" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                    }`}
                                onClick={handleSidebarClick("profile")}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 11.5C12.5938 11.5 14.6875 9.40625 14.6875 6.8125C14.6875 4.21875 12.5938 2.125 10 2.125C7.40625 2.125 5.3125 4.21875 5.3125 6.8125C5.3125 9.40625 7.40625 11.5 10 11.5ZM10 3.53125C11.8125 3.53125 13.2812 5 13.2812 6.8125C13.2812 8.625 11.8125 10.0938 10 10.0938C8.1875 10.0938 6.71875 8.625 6.71875 6.8125C6.71875 5 8.1875 3.53125 10 3.53125Z" fill="currentColor" />
                                        <path d="M19.2191 16.625C16.6566 14.4688 13.4066 13.2812 10.0004 13.2812C6.59412 13.2812 3.34412 14.4688 0.781616 16.625C0.500366 16.875 0.437866 17.3125 0.687866 17.625C0.937866 17.9063 1.37537 17.9688 1.68787 17.7188C4.00037 15.75 6.93787 14.6875 10.0004 14.6875C13.0629 14.6875 16.0004 15.75 18.2816 17.6875C18.4066 17.8125 18.5629 17.8438 18.7504 17.8438C18.9379 17.8438 19.1566 17.75 19.2816 17.5938C19.5316 17.3125 19.5004 16.875 19.2191 16.625Z" fill="currentColor" />
                                    </svg>

                                </span>

                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out ${isAdminMenuVisible ? 'opacity-100' : 'opacity-0'}`}
                                >
                                    Profile
                                </span>
                            </li>

                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-500 hover:gap-4 hover:text-sidebar-black
                     } ${sidebar === "/app/support" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                    }`}
                                onClick={handleLogout}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.1667 13.333L17.5 9.99967M17.5 9.99967L14.1667 6.66634M17.5 9.99967L5.83333 9.99967M10.8333 13.333V14.1663C10.8333 15.5471 9.71405 16.6663 8.33333 16.6663H5C3.61929 16.6663 2.5 15.5471 2.5 14.1663V5.83301C2.5 4.4523 3.61929 3.33301 5 3.33301H8.33333C9.71405 3.33301 10.8333 4.4523 10.8333 5.83301V6.66634"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out ${isAdminMenuVisible ? 'opacity-100' : 'opacity-0'}`}
                                >
                                    Sign out
                                </span>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}

        </div>
    )
}
export default AdminDropMenu;