import React, { useEffect, useState } from 'react';
import Header from "../../UIComponents/Header";
import Lottie from 'lottie-react';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../Redux/Actions/Auth/UpdatePasswordAction';
import { TailSpin } from 'react-loader-spinner';
const API_URL = BASE_URL


export const UpdatePassword = () => {
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const [AnimationData, setAnimationData] = useState(null);
    const [invalidAnimationData, setInvalidAnimationData] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams();
    const [isVerified, setIsVerified] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState({
        "new_password": "",
        "confirm_password": ""
    });
    const [email, setEmail] = useState(null);
    const [errors, setErrors] = useState({});
    const updatePasswordStatus = useSelector((state) => state.update_password_reducer.updatePassword);


    useEffect(() => {
        if (updatePasswordStatus && updatePasswordStatus !== "isLoading" && updatePasswordStatus.length !== 0 && updatePasswordStatus.status === true) {
            setUpdateSuccess(true);
            setIsVerified(false);
        }
    }, [updatePasswordStatus]);

    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/update_password.json')
            .then((response) => response.json())
            .then((data) => setAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);

    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/invalid_link.json')
            .then((response) => response.json())
            .then((data) => setInvalidAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);

    useEffect(() => {
        // Function to verify the token
        const verifyToken = async () => {
            try {
                const response = await fetch(`${API_URL}verify-email/${token}`, {
                    method: 'GET',
                });
                const data = await response.json();
                console.log(data, "data of token");

                if (data.status === true) {
                    setEmail(data.result.email);
                    setIsVerified(true);

                } else {
                    setIsVerified(false);
                }
            } catch (error) {
                console.error('Error verifying token:', error);
                setIsVerified(false);
            } finally {
                setLoading(false);
            }
        };

        verifyToken();
    }, [token, navigate]);
    const validateForm = () => {
        const newErrors = {};
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!formValues.new_password.trim()) newErrors.new_password = "Password is required.";
        else if (!passwordRegex.test(formValues.new_password)) newErrors.new_password = "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character";
        if (!formValues.confirm_password.trim()) newErrors.confirm_password = "Confirm password is required";
        else if (formValues.new_password !== formValues.confirm_password) newErrors.confirm_password = "Password do not match!"
        return newErrors;
    };

    // if (loading || !AnimationData) {
    //     return <div>Loading...</div>;
    // }
    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordVisible(!isNewPasswordVisible);
    };
    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
        setErrors({});
    };
    const handleUpdatePassword = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const body = { ...formValues, email };
            dispatch(updatePassword(body));
        } else {
            // Set errors to display validation messages
            setErrors(formErrors);
        }
    };
    const handleTryAgain = () => {
        navigate("/signin");
    }
    return (
        <div className="w-full h-[100vh] overflow-hidden">
            <Header background={"bg-primary-1"} />
            <div
                className="lg:relative w-full h-[90vh] rounded-md flex items-start lg:items-center justify-center overflow-hidden"
            >
                <div
                    className="hidden lg:block absolute inset-0"
                    style={{
                        backgroundImage: "url('/verify_bg_image.png')",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        zIndex: -10
                    }}
                ></div>
                <div
                    className="lg:absolute lg:top-[center] lg:left-[center] p-[20px] lg:p-[32px] flex flex-col lg:items-center gap-[12px] rounded-[16px] bg-white lg:shadow-[0px_12px_34px_0px_rgba(13,10,44,0.08),0px_34px_26px_0px_rgba(13,10,44,0.05)]"
                >
                    <div className='flex flex-col w-screen px-[20px] gap-[20px] text-font_color-primary lg:w-[504px] lg:gap-[40px]'>
                        {isVerified ? (
                            <>
                                <div className='flex flex-col gap-[8px] lg:gap-[16px]'>
                                    <h3 className='Heading-24 text-center lg:Heading-48'>Let’s Secure your account</h3>
                                    <p className='body-3 text-font_color-body text-center lg:body-2'>Create a new password, make it strong, make it yours!</p>
                                </div>
                                <div className='flex flex-col gap-[12px] lg:gap-[24px]'>
                                    <div className="w-full flex flex-col gap-[6px] lg:gap-[12px]">
                                        <label className="subtitle2 lg:subtitle1">
                                            New Password
                                        </label>
                                        <div className="relative w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center">
                                            <input
                                                type={isNewPasswordVisible ? 'text' : 'password'}
                                                name="new_password"
                                                value={formValues.new_password}
                                                onChange={handleChange}
                                                placeholder="Enter your password"
                                                className="w-full h-full bg-transparent border-none outline-none p-[8px_16px] rounded-[6px]"
                                            />
                                            <div
                                                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                                onClick={toggleNewPasswordVisibility}
                                            >
                                                {isNewPasswordVisible ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-eye"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                    </svg>
                                                )}
                                            </div>
                                        </div>
                                        {errors.new_password && <p className="text-red-500 body-3 lg:body-2">{errors.new_password}</p>}
                                    </div>
                                    <div className="w-full flex flex-col gap-[6px] lg:gap-[12px]">
                                        <label className="subtitle2 lg:subtitle1">
                                            Confirm Password
                                        </label>
                                        <div className="relative w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center">
                                            <input
                                                type={isConfirmPasswordVisible ? 'text' : 'password'}
                                                name="confirm_password"
                                                value={formValues.confirm_password}
                                                onChange={handleChange}
                                                placeholder="Enter your password"
                                                className="w-full h-full bg-transparent border-none outline-none p-[8px_16px] rounded-[6px]"
                                            />
                                            <div
                                                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                                onClick={toggleConfirmPasswordVisibility} // Toggle visibility on click
                                            >
                                                {isConfirmPasswordVisible ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-eye"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                    </svg>
                                                )}
                                            </div>
                                        </div>
                                        {errors.confirm_password && <p className="text-red-500 body-3 lg:body-2">{errors.confirm_password}</p>}
                                    </div>
                                    <button
                                        className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                                        onClick={handleUpdatePassword}
                                    >
                                        {updatePasswordStatus === "isLoading" ? (
                                            <TailSpin color="#FFFFFF" height={20} width={20} />
                                        ) : (
                                            "Update Password"
                                        )}
                                    </button>

                                </div>
                            </>
                        )
                            : (
                                <div className='flex flex-col items-center gap-[16px] lg:gap-[32px]'>
                                    {updateSuccess && (
                                        <>
                                            <Lottie
                                                animationData={AnimationData}
                                                className='h-[168px] lg:h-[300px]'
                                            />
                                            <p className="body-3 text-font_color-body justify-center text-center lg:body-1">
                                                Your password has been successfully updated! Ready to dive back into your learning journey?
                                            </p>
                                            <button
                                                className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                                                onClick={() => window.location.href = '/signin'}
                                            >
                                                Continue to login
                                            </button>
                                        </>
                                    )}
                                    {!updateSuccess && (
                                        <>
                                            <Lottie
                                                animationData={invalidAnimationData}
                                                className='w-[168px] lg:h-[300px]'
                                            // style={{ width: 300, height: 300 }}
                                            />
                                            <p className="body-3 text-font_color-body justify-center text-center lg:body-1">
                                                The verification link you used is either invalid or has expired.
                                            </p>
                                            <button
                                                className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                                                onClick={handleTryAgain}
                                            >
                                                Try Again
                                            </button>
                                        </>

                                    )}

                                </div>
                            )
                        }

                    </div>
                    <footer className="mt-5 body-3 text-font_color-body text-center lg:hidden">
                        <p>© 2024 Knnect. All Rights Reserved.</p>
                    </footer>
                </div>

            </div>

        </div >
    )
}